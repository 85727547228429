import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"

import "../components/styling/layout.css"
import "../components/styling/typography.css"

const LostWrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <>
    <SEO title="404 - Not found" />
    <LostWrapper>
      <h1>PAGE NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </LostWrapper>
  </>
)

export default NotFoundPage
